import iziToast from "izitoast";

export class Notify{
    static success(title:string, content?:string):void{
        iziToast.success({
            title: title,
            message: content ? content : ''
        });
    }
    static error(title:string, content?: string):void{
        iziToast.error({
            title: title,
            message: content ? content : ''
        });
    }

    static confirm(content:string, func_success:() => void, btn_yes_label = 'Tak', btn_no_label = 'Nie'):void{
        iziToast.question({
            // title: title,
            message: content,
            close: false,
            overlay: true,
            position: 'center',
            buttons: [
                ['<button><b>' + btn_yes_label + '</b></button>', function (instance, toast) {
                    instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
                    func_success();
                }, true],
                ['<button>' + btn_no_label + '</button>', function (instance, toast) {
                    instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
                }, false],
            ]
        });
    }
}