import { _rootApi } from "../../../web/global/Api/_root";

export interface ApiResult {
  id: number;
  parent_category_id: number;
  depth: number;
  label: string;
  isDisabled?: boolean;
  children?: ApiResult[];
}

export class ApiAdminCategoriesTree extends _rootApi {
    static request(func_success?: (v: Array<ApiResult>) => void, func_error?: () => void): void {
        this._request({
            url: '/categories/tree',
            func_success: (r: Array<ApiResult>) => {
                if (func_success) func_success(r);
            },
            func_error: () => {
                if (func_error) func_error();
            }
        });
    }
}