import { _rootApi } from "../../../web/global/Api/_root"

export interface ApiParams {
  id: number;
}

export interface ApiResult {
  id: number,
  name_pl: string,
  name_gb: string,
  description_pl?: string,
  description_gb?: string,
  meta_title_pl?: string,
  meta_title_gb?: string,
  meta_description_pl?: string,
  meta_description_gb?: string,
  meta_keywords_pl?: string,
  meta_keywords_gb?: string,
  friendly_url_pl?: string,
  friendly_url_gb?: string,
  is_displayed?: boolean,
  parent_category_id?: number,
  category_image?: string,
  category_thumbnail?: string,
  menu_thumbnail?: string
}

export class ApiAdminCategoryDetails extends _rootApi {
    static request(params: ApiParams, func_success?: (v: ApiResult) => void, func_error?: () => void): void {
        this._request({
            url: `/category/details`,
            params: params as unknown as {[index:string]:string},
            func_success: (r: ApiResult) => {
                if (func_success) func_success(r);
            },
            func_error: () => {
                if (func_error) func_error();
            }
        });
    }
}