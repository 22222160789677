import { _rootApi } from "../../../web/global/Api/_root"

export interface ApiParams {
  name_pl: string,
  name_gb: string,
  description_pl?: string,
  description_gb?: string,
  meta_title_pl?: string,
  meta_title_gb?: string,
  meta_description_pl?: string,
  meta_description_gb?: string,
  meta_keywords_pl?: string,
  meta_keywords_gb?: string,
  friendly_url_pl?: string,
  friendly_url_gb?: string,
  is_displayed?: boolean,
  parent_category_id?: number,
  category_image?: File,
  category_thumbnail?: File,
  menu_thumbnail?: File
}

export interface ApiResult {
  category_id?: number,
  result?: string,
  errors?: { [index: string]: Array<string> },
  message?: string
}

export class ApiAdminCategoryNew extends _rootApi {
  static request(params:ApiParams, func_success?: (r:ApiResult) => void, func_error?: (r:ApiResult) => void, func_finally?: () => void):void{
    this._request({
      url: '/category/new',
      params: params as unknown as {[index:string]:string},
      func_success: (r) => {
        if(func_success) func_success(r);
      },
      func_error: (r) => {
        if(func_error) func_error(r);
      },
      func_finally: () => {
        if(func_finally) func_finally();
      }
    });
  }
}