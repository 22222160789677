import { _rootApi } from "../../../web/global/Api/_root"

export interface ApiParams {
  id_min?: number;
  id_max?: number;
  name?: string;
  index?: string;
  category?: string;
  price_min?: number;
  price_max?: number;
  quantity_min?: number;
  quantity_max?: number;
  isactive?: boolean;
  position?: number;
  sort_by?: ('id' | 'name' | 'index' | 'category' | 'price_net' | 'price_gross' | 'quantity' | 'isactive' | 'position') | string,
  sort_order?: 'asc' | 'desc',
  page?: number,
  per_page?: number;
  ignore_ids?: Array<number>;
}

export interface ApiResult {
  data: Array<ApiResultDataSingle>,
  data_count: number
}

export interface ApiResultDataSingle {
  id: number,
  image_id: number,
  link_rewrite: string,
  name: string,
  index: string,
  category: string,
  price_net: number,
  price_gross: number,
  quantity: number,
  isactive: boolean,
  position: number
}

export class ApiAdminProductsSearch extends _rootApi {
    static request(params: ApiParams, func_success?: (v: ApiResult) => void, func_error?: () => void): void {
        this._request({
            url: '/products/search',
            params: params as unknown as { [index: string]: string },
            func_success: (r: ApiResult) => {
                if (func_success) func_success(r);
            },
            func_error: () => {
                if (func_error) func_error();
            }
        });
    }
}