import { _rootApi } from "../../../web/global/Api/_root"

export interface ApiParams {
  lang_id: number
}

export interface ApiResultIncorrect{
  result?: string,
  errors?: {[index:string]:Array<string>},
  message?: string
}

export interface ApiResultCorrect{
  [index:number]:{
    id: number,
    name: string,
    value_id: number,
    value_name: string
  }
}

export class ApiAdminGeneralFeatures extends _rootApi {
    static request(params:ApiParams, func_success?: (r:ApiResultCorrect) => void, func_error?: (r:ApiResultIncorrect) => void, func_finally?: () => void):void{
        this._request({
            url: '/general/features',
            params: params as unknown as {[index:string]:string},
            func_success: (r) => {
                if(func_success) func_success(r);
            },
            func_error: (r) => {
                if(func_error) func_error(r);
            },
            func_finally: () => {
                if(func_finally) func_finally();
            }
        });
    }
}