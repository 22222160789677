import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_file_pond = _resolveComponent("file-pond")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["uploader", { 'uploader-horizontal': _ctx.horizontal }]),
    ref: "pond_outer"
  }, [
    _createVNode(_component_file_pond, {
      name: "upload",
      ref: "pond",
      allowImagePreview: false,
      allowFileTypeValidation: false,
      "label-idle": "Prześlij plik",
      "allow-multiple": _ctx.multiple,
      itemInsertLocationFreedom: false,
      onInit: _ctx.init,
      files: _ctx.tempfiles_default,
      "max-files": 30,
      maxParallelUploads: 1,
      server: _ctx.api_fileupload_server,
      itemInsertLocation: 'after',
      "capture-method": null,
      onRemovefile: _ctx.tempfile_removed,
      onProcessfile: _ctx.tempfile_processed,
      onUpdatefiles: _ctx.tempfile_updated,
      onAddfile: _ctx.tempfile_added,
      maxFileSize: '50MB',
      labelMaxFileSizeExceeded: 'Rozmiar przekracza limit',
      labelMaxFileSize: 'Można przesłać maksymalnie {filesize}',
      labelFileLoading: 'Ładowanie',
      labelFileProcessing: 'Przesyłanie',
      labelFileProcessingComplete: 'Przesłano',
      labelFileProcessingAborted: 'Przesyłanie zostało anulowane',
      labelFileProcessingError: 'Przesyłanie nie powiodło się',
      labelTapToUndo: "",
      labelTapToCancel: "",
      labelButtonRemoveItem: 'Usuń'
    }, null, 8, ["allow-multiple", "onInit", "files", "server", "onRemovefile", "onProcessfile", "onUpdatefiles", "onAddfile"])
  ], 2))
}