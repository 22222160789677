import { createApp } from 'vue';

import App from './views/App.vue';
import router from './router';
import '../../assets/library/jquery/jquery.min.js';
import '../../assets/library/jquery-ui/jquery-ui.min.js';
import '../../assets/library/bootstrap/js/bootstrap.bundle.min.js';
import '../../assets/library/adminlte/dist/js/adminlte.min.js';
import '../../../node_modules/izitoast/dist/css/iziToast.min.css';
import "../../../node_modules/filepond/dist/filepond.min.css";
import "../../../node_modules/filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import "vue-select/dist/vue-select.css";
import 'vue-treeselect-next/dist/vue-treeselect.css';
import { Global } from '@/web/global/Global';
import { Account } from './global/Account';
import { _rootApi } from '../web/global/Api/_root';

_rootApi.redirect_logout = () => {
    router.push('/login');
};
Global.is_web = false;
Account.init();

const app = createApp(App).use(router).mount('#app');