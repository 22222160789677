import { _rootApi } from "../../../web/global/Api/_root"

export interface ApiResult{
  disabled_categories_count: number,
  empty_categories_count: number,
  best_category: string,
  average_products_per_category: number
}

export class ApiAdminCategoriesGeneral extends _rootApi {
    static request(func_success?: (v: ApiResult) => void, func_error?: () => void): void {
        this._request({
            url: '/categories/general',
            func_success: (r: ApiResult) => {
                if (func_success) func_success(r);
            },
            func_error: () => {
                if (func_error) func_error();
            }
        });
    }
}