import { _rootApi } from "../../../web/global/Api/_root"

export interface ApiParams {
  id?: number;
  name?: string;
  parent_name?: string;
  description?: string;
  position?: number;
  isactive?: boolean;
  sort_by?: ('id' | 'name' | 'description' | 'position' | 'isactive') | string,
  sort_order?: 'asc' | 'desc',
  page?: number,
  per_page?: number;
}

export interface ApiResult {
  data: Array<ApiResultDataSingle>,
  data_count: number
}

export interface ApiResultDataSingle {
  id: number,
  name: string,
  description: string,
  position: number,
  isactive: boolean,
  parent_name: string
}

export class ApiAdminCategoriesSearch extends _rootApi {
    static request(params: ApiParams, func_success?: (v: ApiResult) => void, func_error?: () => void): void {
        this._request({
            url: '/categories/search',
            params: params as unknown as { [index: string]: string },
            func_success: (r: ApiResult) => {
                if (func_success) func_success(r);
            },
            func_error: () => {
                if (func_error) func_error();
            }
        });
    }
}