
import { Options, Vue } from 'vue-class-component';
// @ts-ignore
import vueFilePond from "vue-filepond";
import FilePondPlugiImagePreview from 'filepond-plugin-image-preview';
import { FilePond } from 'filepond';

export interface TaskDataTypeSingleFile{
    "id": number,
    "name": string,
    "author_name": string,
    "extension": string,
    "size": number,
    "key_hash": string,
    "created_at": string,
    "is_fortask": boolean
}

export interface filepondFile{
  source: string,
  options: {
      type:string,
      file: {
          name:string,
          size:number,
          type:string
      }
  }
}

export interface FilePondInstructions{
  data: {
      crop: {
          center: {
              x: number,
              y: number
          },
          flip: {
              horizontal: boolean,
              vertical: boolean
          },
          zoom: number,
          rotation: number,
          aspectRatio: number
      }
  }
}

export interface FileUploadResponse{
  id: number,
  hash: string
}

const _FilePond = vueFilePond(
  FilePondPlugiImagePreview
);

@Options({
  props: {
      reset_flag: Boolean,
      files_uploaded: Array,
      horizontal: Boolean,
      values_updated: Function,
      multiple: Boolean
  },
  components: {
      // eslint-disable-next-line
      // @ts-ignore
      'file-pond': _FilePond
  }
})

/**
* イベントリファレンス
* https://pqina.nl/filepond/docs/api/instance/events/
*/
export default class Uploader extends Vue {
  reset_flag = false;
  horizontal = false;
  files_uploaded: Array<TaskDataTypeSingleFile> = [];
  values_updated: (status:boolean, filelist_html:string, filelist_toadd_ids: Array<number>, filelist_todelete_ids: Array<number>, filelist_hash_values: {[index:number]: string}) => void = () => null;
  multiple = true;

  api_fileupload_server = {
      'process': process.env.BASE_URL + '../api/tempfile/process'
  };

  pond_isloading = false;
  pond_is_inited = false;
  tempfiles:{[index:string]:number} = {};
  tempfiles_default:Array<filepondFile> = [];
  tempfiles_before:{[index:string]:number} = {}; // 初期状態
  tempfiles_names:{[index:string]:string} = {}
  tempfiles_hash_values:{[index:string]:string} = {};

  declare $refs: {
      "pond": FilePond,
      "pond_outer": HTMLElement
  };

  mounted():void{
      this.$watch(() => this.reset_flag, () => {
          this.tempfiles = {};
          this.tempfiles_default = [];
          this.tempfiles_before = {};
          this.tempfiles_names = {};
          this.tempfiles_hash_values = {};

          if(this.pond_is_inited){
              this.init();
          }
      });

      this.$watch(() => this.files_uploaded, () => {
          if(this.pond_is_inited){
              this.init();
          }
      });
  }

  init():void{
      this.pond_is_inited = true;

      // 既にファイルがアップされていた場合、調整
      if(typeof(this.files_uploaded) !== "undefined" && this.files_uploaded.length > 0){
          const r:Array<filepondFile> = [];

          (this.files_uploaded).forEach(file => {
              r.push({
                  // "source": file.id + '',
                  "source": JSON.stringify({id : file.id, hash : file.key_hash }),
                  "options": {
                      "type": 'local',
                      "file": {
                          "name": file.name,
                          "size": file.size,
                          "type": file.extension
                      }
                  }
              });
          });
          this.tempfiles_default = r;

          setTimeout(() => {
              const btns1 = this.$refs.pond_outer.querySelectorAll(".filepond--list .filepond--item .filepond--file .filepond--action-abort-item-load") as unknown as Array<HTMLButtonElement>;
              const btns2 = this.$refs.pond_outer.querySelectorAll(".filepond--list .filepond--item .filepond--file .filepond--action-retry-item-load") as unknown as Array<HTMLButtonElement>;
              [...btns1, ...btns2].forEach(item => {
                  item.setAttribute("style","");
                  item.disabled = false;
              });

              (this.$refs.pond_outer.querySelectorAll(".filepond--list .filepond--item .filepond--file .filepond--action-remove-item") as unknown as Array<HTMLButtonElement>).forEach(item => {
                  item.setAttribute("style", "display:none;");
              });

              (this.$refs.pond_outer.querySelectorAll(".filepond--list .filepond--item .filepond--file-info") as unknown as Array<HTMLButtonElement>).forEach(item => {
                  item.setAttribute("style", "transform: translate3d(0px, 0px, 0px);");
              });

              btns2.forEach(btn => {
                  const path = btn.querySelector("svg path") as SVGSVGElement;

                  path.setAttribute("d", 'M11.586 13l-2.293 2.293a1 1 0 0 0 1.414 1.414L13 14.414l2.293 2.293a1 1 0 0 0 1.414-1.414L14.414 13l2.293-2.293a1 1 0 0 0-1.414-1.414L13 11.586l-2.293-2.293a1 1 0 0 0-1.414 1.414L11.586 13z');
                  btn.onclick = () => {
                      (((btn.parentElement as HTMLElement).parentElement as HTMLDivElement).querySelector(".filepond--action-remove-item") as HTMLElement).click();
                  };
              });
          
          }, 500);
      }
  }

  tempfiles_check_uploadstatus():void{
      this.pond_isloading = this.$refs.pond.getFiles().filter(x => (x.status !== 5 && x.status !== 8)).length !== 0;

      let fileslist_html = '';
      if(typeof(this.tempfiles_names) !== "undefined" && (Object.keys(this.tempfiles_names)).length > 0){
          fileslist_html = Object.values(this.tempfiles).map(Number).sort((a,b) => a - b)
              .map(v => this.tempfiles_names[
                  Object.keys(this.tempfiles).filter(key => this.tempfiles[key] === v)[0]
              ]).join('<br>');
      }

      // アップ用、削除用ファイルを調べる
      let filelist_toadd_ids:Array<number> = [];
      let filelist_todelete_ids:Array<number> = [];
      let filelist_hash_values:{[index:number]: string} = {};

      const unlink_tempfile:{[index:string]:number} = Object.assign({},this.tempfiles_before);
      const link_files:Array<number> = [];
      const keys:Array<string> = Object.keys(this.tempfiles);
      keys.forEach(key => {
          if(typeof(this.tempfiles_before[key]) !== "undefined"){
              delete unlink_tempfile[key];
          }
          else{
              link_files.push(this.tempfiles[key]);
              filelist_hash_values[this.tempfiles[key]] = this.tempfiles_hash_values[key];
          }
      });
      if(link_files.length > 0){ filelist_toadd_ids = link_files.sort((a,b) => a - b); }
      if(Object.keys(unlink_tempfile).length > 0){
          // filelist_todelete_ids = Object.keys(unlink_tempfile).map(key => unlink_tempfile[key]);
          for (let key in unlink_tempfile){
             filelist_todelete_ids.push(unlink_tempfile[key]);
              filelist_hash_values[unlink_tempfile[key]] = this.tempfiles_hash_values[key];
          }
          filelist_todelete_ids = filelist_todelete_ids.sort((a,b) => a - b);
      }

      if(typeof this.values_updated === "function"){
        this.values_updated(
            this.pond_isloading,
            fileslist_html,
            filelist_toadd_ids,
            filelist_todelete_ids,
            filelist_hash_values
        );
      }
  }

  /**
   * ファイルアップロード時に発火する処理
   * 具体的に下記のタイミングで発火する：
   * １．ファイルを追加しようとする時（新しいファイルのアップロードがまだ始まってない時点）
   * ２．tempfile_added処理が終わった時
   * ３．tempfile_removed処理が終わった時
   */
  tempfile_updated():void{
      this.tempfiles_check_uploadstatus();
  }

  /**
   * ファイルの追加終わり次第、発火するメソッド
   * 具体的に下記のタイミングで発火する：
   * １．ファイルのアップロードが完全に終わった時
   */
  tempfile_processed(request:{code:number}, file:{id: string, serverId: string, filename:string, status:number}):void{
      if(typeof(request) !== "undefined" && request !== null && typeof(request.code) !== "undefined" && request.code !== 200 && file.status !== 5){ return; }

      const upload_response = JSON.parse(file.serverId) as FileUploadResponse;
      this.tempfiles[file.id] = Number(upload_response.id);
      this.tempfiles_names[file.id] = file.filename;
      this.tempfiles_hash_values[file.id] = upload_response.hash;

      this.tempfiles_check_uploadstatus();
  }

  /**
   * ファイル追加メソッド
   * 具体的に下記のタイミングで発火する：
   * １．新規ファイルアップロードが始まる時（アップロード中という文字が表示された時）
   * ２．ファイル情報がtempfiles_defaultの変数に代入された時
   */
  tempfile_added(request:{code: number}, file:{id: string, serverId: string, filename:string}):void{
      if(typeof(file.serverId) === "undefined" || file.serverId == null){ return; } // サーバーからのファイルの場合、処理不要

      const upload_response = JSON.parse(file.serverId) as FileUploadResponse;
      this.tempfiles[file.id] = Number(upload_response.id);
      this.tempfiles_before[file.id] = Number(upload_response.id);
      this.tempfiles_names[file.id] = file.filename;
      this.tempfiles_hash_values[file.id] = upload_response.hash;

      this.tempfiles_check_uploadstatus();
  }

  /**
   * ファイル削除メソッド
   * 具体的に下記のタイミングで発火する：
   * １．ファイル削除しようとする時（×ボタンが押された時など）
   */
  tempfile_removed(request:string, file:{id:string}):void{
      delete this.tempfiles[file.id];
      delete this.tempfiles_names[file.id];

      this.tempfiles_check_uploadstatus();
  }
}

